import React from 'react';

const Content = () => {
  return (
  <div className="content">
    <div className="mainContainer">
      <div id="down"></div>
        <div className="columns">
          <h2>Server IP: vanilla.steeviecraft.de</h2>
          <p>
            Der Serverbeitritt wird über eine Whitelist geregelt.
            Falls ihr also mitspielen wollt, schreibt mich persönlich oder einen Moderator an. Ihr erhaltet dann eine kurze Einführung und könnt im Anschluss gleich loslegen. Da es ein reiner Vanilla Server ist, gibt es keine Plugins, die Eigentum schützen, deswegen ist eine Verhaltensetikette wichtig! Mehr dazu in den Regeln.
            Schaut bei Interesse doch auf <a href="https://discord.gg/ysK2b8a">Discord</a> vorbei und stellt euch vor!
          </p>
        </div>
        <div className="columns">
          <h2>Regeln:</h2>
          <p>
            Seid freundlich und respektvoll zueinander
            Achtet das Privateigentum der Mitspieler
            ( Truhen, Häuser u.ä. sind immer privat, es sei denn ein Schild sagt etwas anderes )
            Kämpfe gegen Mitspieler (PVP) nur nach beiderseitiger Zustimmung
            Cheating/Ausnutzen von Bugs und Texture Packs wie z.B. X-Ray sind verboten
            Spam in sämtlichen Chats ist unerwünscht.<br/>
            Bei Verstößen gegen obige Regeln oder unerwünschtem Verhalten behalte ich mir vor dem jew. Spieler den Whitelisteintrag temporär oder permanent zu entziehen.
          </p>
        </div>
        <div className="columns">
          <h2>Nützliche Links:</h2>
          <p>
            Etwas mehr FPS und mehr Einstellungen gefällig?
            Das geht mit Optifine! Dazu müsst ihr Java auf eurem Rechner installiert haben. Anschließend einfach auf Optifine doppelklicken und installieren.
          </p>
        </div>
        <div className="columns">
          <p>
            <table>
             <tr>
               <td>Minecraft Client:</td>
               <td><a href="https://www.minecraft.net/de-de/download/alternative/">minecraft.net</a></td>
             </tr>
             <tr>
               <td>Java:</td>
               <td><a href="https://www.java.com/de/download/">java.com</a></td>
             </tr>
             <tr>
               <td>Optifine:</td>
               <td><a href="https://optifine.net/downloads">optifine.net</a></td>
             </tr>
            </table>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Content
