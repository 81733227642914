import React from 'react';
import {useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Banner = () => {
  const data = useStaticQuery( graphql`
    query {
      castle: file(relativePath: { eq: "castle.webp"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pyramid: file(relativePath: { eq: "pyramid.webp"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bees: file(relativePath: { eq: "bees.webp"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }`
  )

  return (
    <div className="banner">
      <div className="container">
        <div className="row">
          <div className="main-text">Steeviecraft.de</div>
          <div className="main-image">
            <Img fluid={data.castle.childImageSharp.fluid} />
          </div>
          <div className="side-image left">
            <Img fluid={data.bees.childImageSharp.fluid} />
          </div>
          <div className="side-image right">
            <Img fluid={data.pyramid.childImageSharp.fluid} />
          </div>
        </div>
        <div className="scroll">
          <a href="#down">scroll down</a>
        </div>
      </div>
    </div>
  )
}

export default Banner
