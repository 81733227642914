import * as React from "react"
import "../styles/styles.scss"

//Components
import Header from "../components/header"
import Banner from "../components/banner"
import Content from "../components/content"
import Footer from "../components/footer"

const IndexPage = () => (
    <div>
      <Header />
      <Banner />
      <Content/>
      <Footer />
    </div>
)

export default IndexPage
