import * as React from "react"

const Footer = ({ siteTitle }) => (
  <footer>
    <div className="container">
      <span>© 2021 Stefan Brandl</span>
    </div>
  </footer>
)

export default Footer
