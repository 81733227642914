import * as React from "react"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header>
    <div className="container">
      <div className="inner-header">
        <div className="logo">
          <Link to="/">
            Steeviecraft.de | Alle Infos über meinen Minecraftserver
          </Link>
        </div>
        <div className="navigation">
          <nav>
            <Link to="/">Home</Link>
            <Link to="/map">Map</Link>
            <Link to="https://discord.gg/ysK2b8a">Discord</Link>
          </nav>
        </div>
      </div>
    </div>
  </header>
)

export default Header
